import moment from 'moment';
import _padStart from 'lodash/padStart';

const format = (date, formatStr) => {
  return moment.isMoment(date) ? 
    date.format(formatStr ? formatStr : 'YYYY-MM-DD')
    : moment(date).format(formatStr ? formatStr : 'YYYY-MM-DD');
};

const today = () => moment().format('YYYY-MM-DD');

const now = () => moment().format('YYYY-MM-DD HH:mm:ss');

const formatDateTime = date => format(date, 'YYYY-MM-DD HH:mm:ssA');

const getNowDateString = () => {
  const date = new Date();
  const year: string = date.getFullYear().toString().substring(2);
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();

  return `${_padStart(month, 2, '0')}/${_padStart(day, 2, '0')}/${year}`;
};

/**
 * Get the difference between two datetime values.
 * @param {string | Date | moment.Moment} datetime1 - The first datetime value.
 * @param {string | Date | moment.Moment} datetime2 - The second datetime value.
 * @param {boolean} [fromNow=false] - If true, calculate the difference from the current time to datetime1.
 * @returns {string} - The difference.
 */
const getDistance = (datetime1, datetime2) => {
  if (!datetime1 || !datetime2) {
    return 'Invalid datetime format';
  }

  const moment1 = moment(datetime1);
  const moment2 = moment(datetime2);

  if (!moment1.isValid() || !moment2.isValid()) {
    return 'Invalid datetime format!';
  }

  const diff = moment2.diff(moment1);
  return moment.duration(Math.abs(diff)).humanize();
}

const isEarlierThanDurationAgo = (datetime, duration, durationUnit) => {
  const moment1 = moment(datetime);
  const moment2 = moment().subtract(duration, durationUnit);

  return moment1.isBefore(moment2);
}

const isValidDate = date => {
  return moment(date).isValid();
}

const getUsFormattedDate = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });
};

const DateUtil = {
  format,
  today,
  now,
  formatDateTime,
  getNowDateString,
  getDistance,
  isEarlierThanDurationAgo,
  isValidDate,
  getUsFormattedDate,
};

export default DateUtil;
