import StoryMetadata from "../types/metadata";

export const META_DATA_TEXT_FIELDS = [
  {
    required: false,
    text: 'Web headline',
    field: StoryMetadata.HeadlinesWeb,
    isOnlyText: true
  },
  {
    required: false,
    text: 'Meta headline',
    field: StoryMetadata.HeadlinesMetaTitle,
    isOnlyText: true
  },
];

export const isGuestAuthor = (author) => author?.name && !author?._id;

export const getAuthorOptions = (authors) => {
  if (!authors) {
    return null;
  }

  return authors.map(author => {
    if (author.type === 'author') {
      const name = author?.name;

      if (!isGuestAuthor(author)) return { label: name, value: author._id };

      return {
        label: `${author?.org || 'Guest'}: ${name}`,
        value: name,
        ...author,
      };
    }

    return {
      label: author?.referent?.referent_properties?.name || author?.name || '',
      value: author?.referent?.id || author?._id,
      ...author,
    };
  });
};
