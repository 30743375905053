import {
  postData,
  queryGraphQL,
  getData,
  getDataNoAuth,
  putData,
  deleteData,
  fetchApi,
} from '../utils/fetch-api';
import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _merge from 'lodash/merge';
import _values from 'lodash/values';
import { inqRandom } from '../components/inkblog/services/random-id';
import { EditorState, convertFromRaw } from 'draft-js';
import { getHtmlText, punycodeUcs2Decode } from '../utils/string-helper';
import StoryMetadata from '../components/inkblog/types/metadata';
import { ILightBoxResponseItem } from '../shared/types';
import { publishPhoto, publishGallery, getGalleryElements } from './photo-service';
import { publishVideo } from './video-service';
import { isInteractiveHTMLCard } from '../components/inkblog/utils/card';
import { handleStoryCirculation } from './circulation-service';
import { getLocalItemById, setLocalItemById } from '../utils/localstorage';
import { ensureHttps } from '../utils/url-helper';
import { isGuestAuthor } from '../components/inkblog/utils/article-metadata-helper';

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const baseSlsUrl = `${process.env.REACT_APP_BASE_API_URL}`;

function getStoriesByIds(ids) {
  return postData(`${baseUrl}/stories/ids`, ids);
}

function getAutoCuratedStories() {
  return getData(`${baseSlsUrl}feeds/rest/homepage-feeds`, false);
}

function getCuratedStories() {
  const query = `
    query {
      feeds {
        top_stories {
          id
          title
          image_url
          author
        }
      }
    }
  `;

  return queryGraphQL(`${baseSlsUrl}feeds/graphql`, query);
}

function searchStoriesByGraphQL(q, size = 20) {
  const query = `
    query {
      searchPublishedStories(query: "${q}", options:{sort: "display_date:desc", maxRelevantSize:${size}}) {
        id
        title
        item_url
        synopsis
        image_url
        author
        subtype
        cropInfo
        article_tags
        creditsBasic
        taxonomy {
          primary_section
        }
      }
    }
  `;

  return postData(`${baseSlsUrl}site-search/graphql`, { query });
}

const getDemoArticles = () => {
  const query = 'query {getDemoArticleData}';

  return postData(`${baseSlsUrl}stories/graphql`, { query });
};

const addDemoArticles = (ids) => {
  const query = `
    mutation addDemoArticles ($ids: [String]) {
      upsertDemoArticleData(articleIds: $ids)
    }
  `;

  const variables = { ids };

  return queryGraphQL(`${baseSlsUrl}stories/graphql`, query, variables);
};

const getStoryById = (id, query) => {
  const queryExe = query || `
    query ($id: String!, $retryWithUnpublished: Boolean) {
      getStory(id: $id, retryWithUnpublished: $retryWithUnpublished) {
        article {
          title
          item_url
          image_url
          image_srcset
          image_credit
          subtype
          content_elements {
            content
          }
        }
      }
    }`;

  return queryGraphQL(`${baseSlsUrl}stories/graphql`, queryExe, { id, retryWithUnpublished: true });
};

const getArticlesByIds = (ids) => {
  const query = `
    query ($ids: String!) {
      getStories(ids: $ids) {
        articles {
          id
          image_url
          headline
          item_url
          image_srcset
          taxonomy {
            primary_section
          }
          article_tags
        }
      }
    }`;

  const variables = { ids };

  return queryGraphQL(`${baseSlsUrl}stories/graphql`, query, variables);
};

const _getSearchUrl = (size = 10, keyword: '') => {
  return [
    `${baseSlsUrl}arc-content/arc-proxy/content/v4/search/?size=${size}&sort=last_updated_date:desc&website=philly-media-network&_sourceInclude=headlines.basic,promo_items.basic.url,canonical_url,display_date,last_updated_date&q=NOT (taxonomy.sites._id:("/zzz-systest")) AND type:story AND subtype:"subtype-live" AND (NOT subtype:"subtype-live-event")`,
    keyword.length > 0 ? `headlines.basic:"${keyword}"` : ''
  ].filter(item => item).join(' AND ');
}

const _getSearchUrlSwipeable = (size = 10, keyword: '') => {
  return [
    `${baseSlsUrl}arc-content/arc-proxy/content/v4/search/?size=${size}&sort=last_updated_date:desc&website=philly-media-network&_sourceInclude=headlines.basic,promo_items.basic.url,canonical_url,display_date,last_updated_date&q=NOT (taxonomy.sites._id:("/zzz-systest")) AND type:story AND subtype:"subtype-swipeable" AND (NOT subtype:"subtype-live") AND (NOT subtype:"subtype-live-event")`,
    keyword.length > 0 ? `headlines.basic:"${keyword}"` : ''
  ].filter(item => item).join(' AND ');
}

const searchAllLiveBlog = (size = 10) => {
  return getDataNoAuth(_getSearchUrl(size, ''));
}

const searchAllSwipeable = (size = 10) => {
  return getDataNoAuth(_getSearchUrlSwipeable(size, ''));
}

const searchArticleByHeadline = (keyword) => {
  return getDataNoAuth(_getSearchUrl(75, keyword));
}

const getLatestRevisionId = async (id) => {
  try {
    const { revisions } = await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision`);

    if (!revisions || !revisions.length) return '';

    revisions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return revisions[0].id;
  } catch (error) {
    console.error('Error fetching or processing data:', error);
    return '';
  }
};

export const getLatestArticleCirculation = async (id, ans) => {
  try {
    const { circulations = [] } = await getCirculationsByArticleId(id) || {};

    if (circulations?.length) {
      const primarySectionId = circulations?.[0]?.website_primary_section?.referent?.id || '';
      const primarySectionWebsite = circulations?.[0]?.website_primary_section?.website || '';
      const sections = circulations?.[0]?.website_sections?.map(({ referent }) => ({
        _id: referent?.id || '',
        website: referent?.website || 'philly-media-network',
      }));
      const newPrimarySection = ans?.taxonomy?.primary_section?._id ? ans?.taxonomy?.primary_section : { _id: primarySectionId, website: primarySectionWebsite };
      const newSections = ans?.taxonomy?.sections?.some(section => section?._id) ? ans?.taxonomy?.sections : sections;

      ans = {
        ...ans,
        taxonomy: {
          ...ans?.taxonomy,
          primary_section: newPrimarySection || {},
          primary_site: ans?.taxonomy?.primary_site || { _id: primarySectionId },
          sections: newSections || [],
          sites: ans?.taxonomy?.sites || sections || [],
        },
      };
    }

    return ans;
  } catch (error) {
    console.warn('Error fetching circulations:', error);
    return ans;
  }
};

const getArcArticleById = async (id, revisionId, options) => {
  let latestRevisionId = revisionId;

  if (!latestRevisionId) {
    latestRevisionId = await getLatestRevisionId(id);
  }

  const data = await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/${latestRevisionId}`);
  let { ans, document_id } = data;
  const contentElements = ans?.content_elements?.map(item => {
    const randomId = inqRandom();

    return {
      ...item,
      _id: randomId,
      ...item?.additional_properties && {
        additional_properties: {
          ...item.additional_properties,
          _id: randomId,
        }
      }
    };
  });

  if (options?.firstImport) {
    ans = await getLatestArticleCirculation(id, ans);
  }

  return { ...ans, _id: document_id, content_elements: contentElements };
}

const _getSearchPhotoUrl = (keyword, type = 'photo', size = 50, page = 1) => (
  `${baseSlsUrl}arc-content/search-photo-video?limit=${size}&page=${page}&type=${type}${keyword?.length > 0 ? `&searchTerm=${keyword}` : ''}`
)

const searchPhoto = (keyword, size = 50, page = 1) => {
  return getDataNoAuth(_getSearchPhotoUrl(keyword, 'photo', size, page));
}

const searchPhotoLightboxes = (
  currentPage: number,
  itemPerPage: number,
  imageType: string[],
  maxWidth = 220,
  fullTextQuery = '',
  startDate?: number,
  endDate?: number
): Promise<ILightBoxResponseItem> => {
  const offset = currentPage * itemPerPage;

  // check if offset is not NaN
  if (isNaN(offset)) {
    return Promise.resolve([]);
  }

  const queryParams = new URLSearchParams({
    type: 'lightboxes',
    offset: currentPage * itemPerPage,
    limit: itemPerPage,
    maxWidth,
    ...(imageType.length > 0 && { imageType: imageType.join(',') }),
    ...(fullTextQuery && { fullTextQuery }),
    ...(startDate && { startDate }),
    ...(endDate && { endDate })
  });

  const url = `${baseSlsUrl}arc-content/search-photo-video?${queryParams.toString()}`;

  return getDataNoAuth(url);
}

const searchVideo = async (keyword, size = 10, page = 1) => {
  const response = await getDataNoAuth(_getSearchPhotoUrl(keyword, 'video', size, page));

  return response?.records || [];
}

const searchGallery = (keyword, size = 50, page = 1) => (
  getDataNoAuth(_getSearchPhotoUrl(keyword, 'gallery', size, page))
)

const COMMISSION_CACHE_KEY = 'airtable-commission-cache';
const SITE_SECTIONS_CACHE_KEY = 'airtable-site-sections-cache';
const GLOBAL_TAGS_CACHE_KEY = 'global-tags-cache';

const getCacheCommissionTeam = () => getLocalItemById(COMMISSION_CACHE_KEY) || [];
const getCacheSiteSections = () => getLocalItemById(SITE_SECTIONS_CACHE_KEY) || [];
const getCacheGlobalTags = () => getLocalItemById(GLOBAL_TAGS_CACHE_KEY) || [];

const getCommissionTeam = async () => {
  try {
    const teams = await getDataNoAuth(`${baseSlsUrl}airtable/get-commission-team`);
    setLocalItemById(COMMISSION_CACHE_KEY, teams);

    return teams;
  } catch (_) {
    return getCacheCommissionTeam();
  }
};

const getAuthorBySearch = (byline) => getDataNoAuth(`${baseSlsUrl}airtable/search-authors?byline=${byline}`);

const getSiteSections = async () => {
  try {
    const sections = await getDataNoAuth(`${baseSlsUrl}airtable/get-site-sections`);
    setLocalItemById(SITE_SECTIONS_CACHE_KEY, sections);

    return sections;
  } catch (_) {
    return getCacheSiteSections();
  }
}

const getGlobalTags = async () => {
  try {
    const response = await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/tags`);
    const globalTags = response?.Payload?.items || [];
    setLocalItemById(GLOBAL_TAGS_CACHE_KEY, globalTags);

    return globalTags;
  } catch (_) {
    return getCacheGlobalTags();
  }
}

const getFullContentArticleById = async (id, options) => {
  let article = await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/content/v4/?_id=${id}&website=philly-media-network&published=false`);

  if (options?.isFirstImport) {
    article = await getLatestArticleCirculation(id, article);

    // filter by _id is normal author, name is guest author
    const authors = _get(article, 'credits.by', [])
      .filter((author) => author?._id || author?.name)
      .map((author) => ({ ...author, name: author?.name || author?._id || ''}));

    article = {
      ...article,
      credits: {
        ...article?.credits,
        by: authors,
      },
      [StoryMetadata.Credits]: authors,
    };
  }

  return article;
}

const fieldsToExcludeInDraftUpdate = [
  'websites',
  'canonical_url',
  'taxonomy.sections',
  'taxonomy.sites',
  'taxonomy.primary_section',
  'taxonomy.primary_site',
  'inkCards',
  'canonical_url',
  'publish_date',
  'publishing',
  'cards',
  'distributor',
  'primary_section',
  'secondary_sections',
  ..._values(StoryMetadata)
];

const splitParagraphs = (element) => {
  if (typeof element?.content === 'string') {
    const paragraphs = element?.content?.split(/(<p[^>]*>.*?<\/p>)/g);

    if (paragraphs?.length > 1) {
      return paragraphs?.map((value) => ({
        ...element,
        content: value,
        _id: inqRandom(),
      }))?.filter(item => item?.content);
    }
  }

  return [element];
};

const getStoryLength = (contentElements) => {
  if (!contentElements?.length) {
    return {
      character_count_actual: 0,
      character_encoding: "UTF-16",
      inch_count_actual: 0,
      line_count_actual: 0,
      word_count_actual: 0,
    };
  }

  const blocks = contentElements.reduce((acc, element) => {
    const { _id, type, content, items } = element;

    if (['header', 'text'].includes(type)) {
      return [...acc, {
        key: _id,
        text: getHtmlText(content),
        type: type === 'header' ? 'header-two' : 'unstyled',
      }];
    }

    if (type === 'list') {
      const list = items.map(({ _id, content }) => {
        return {
          key: _id,
          text: getHtmlText(content),
          type: "unordered-list-item",
        }
      });

      return [...acc, ...list]
    }

    return acc;
  }, []);

  const editorState = EditorState.createWithContent(
    convertFromRaw({
      blocks: blocks,
      entityMap: {},
    })
  );

  const plainText = editorState.getCurrentContent().getPlainText('');
  const blockArray = editorState.getCurrentContent().getBlocksAsArray();
  const regex = /(?:\r\n|\r|\n)/g;
  const wordArray = plainText.replace(regex, ' ').trim().match(/\S+/g);
  const characters = punycodeUcs2Decode(plainText.replace(regex, '').trim()).length;
  const lines = blockArray?.length || 0;
  const linesPerInch = 7;

  return {
    character_count_actual: characters || 0,
    character_encoding: "UTF-16",
    inch_count_actual: Math.ceil(lines / linesPerInch),
    line_count_actual: lines,
    word_count_actual: wordArray?.length || 0,
  };
}

const updateImagesInGallery = async (id, photos) => {
  const data = await getGalleryElements(id);
  const galleryVersion = _get(data, 'additional_properties.version', 0);
  const imageCoverId = _get(data, 'promo_items.basic._id', '');

  photos = photos.map(photo => ({
    ...photo,
    galleryVersion,
    cover: imageCoverId === photo._id,
  }));

  return putData(`${baseSlsUrl}arc-content/arc-proxy/photo/api/v2/galleries/${id}/photos`, photos, true);
}

const moveItemToTop = (items, index) => {
  const newItems = [...items];

  if (index !== -1) {
    const movedItem = newItems.splice(index, 1)[0];
    newItems.unshift(movedItem);
  }

  return newItems;
};

const sortContentElements = (items) => {
  let sortedItems = [...items];

  const headerIndex = sortedItems.findIndex((item) => item?.type === 'header');
  sortedItems = moveItemToTop(sortedItems, headerIndex);

  const timestampIndex = sortedItems.findIndex((item) => item?.content?.includes('// Timestamp'));
  sortedItems = moveItemToTop(sortedItems, timestampIndex);

  const pinnedIndex = sortedItems.findIndex((item) => item?.content?.includes('// Pinned'));
  sortedItems = moveItemToTop(sortedItems, pinnedIndex);

  return sortedItems;
};

const RELATED_LINK_TEXT = '// RelatedLink Text';
const LIVEBLOG_NAME_TEXT = '// LiveBlog Name';
const IGNORE_ARC_FIELDS = [
  'headlines.basic',
  'description',
  'content_elements',
  'promo_items',
  'planning',
  'headlines.web',
  'headlines.meta_title',
  'slug',
  'taxonomy.seo_keywords',
  'taxonomy.tags',
  'label.commission_team_1_required',
  'label.commission_team_2',
  'credits',
];

const getCreditsAuthors = (credits) => {
  return credits?.by?.map((author) => {
    if (isGuestAuthor(author)) {
      return _omit(author, ['label', 'value']);
    }

    return {
      type: 'reference',
      referent: {
        id: author?._id,
        type: 'author',
        referent_properties: {
          name: author?.name,
        },
      },
    };
  });
};

const saveDraftArcArticleById = async (id, article, isDirectUpdate = false, revisionId) => {
  if (isDirectUpdate) {
    return await putData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/draft`, {
      ans: article,
    }, true);
  }

  const miniNavCard = article?.inkCards?.find(card => card.type === 'mini_nav_card');
  const interactiveHtmlCard = article?.inkCards?.find(card => isInteractiveHTMLCard(card?.type));
  const cards = [...(article?.inkCards || [])].filter(card => card.type !== 'mini_nav_card' && !isInteractiveHTMLCard(card?.type)).map((inkCard, index) => {
    const card = { ...inkCard };
    const hasPinnedElement = (card?.elements || []).find(element => typeof element?.content === 'string' && element?.content?.includes('// Pinned'));

    if (card?.elements?.length === 0) {
      return card;
    }

    if (!card?.isPinned) {
      hasPinnedElement && card?.elements?.shift();
    } else {
      !hasPinnedElement && card?.elements?.unshift({
        content: '// Pinned',
        type: 'text',
        _id: inqRandom()
      });
    }

    if (index === article?.inkCards?.length - 1) {
      return card;
    }

    const divider = {
      _id: inqRandom(),
      type: 'divider',
    };

    return {
      ...card,
      elements: [...sortContentElements(card?.elements), divider],
    };
  });

  let contentElements = _flatMap(cards || [], 'elements')
    .reduce((accumulator, currentObject) => {
      if (currentObject?.type === 'text') {
        const paragraphs = splitParagraphs(currentObject);

        return [...accumulator, ...paragraphs];
      }

      if (currentObject?.type === 'author') {
        const authorText = currentObject?.authors?.map((author, index) => {
          const bioPage = author?.additional_properties?.original?.bio_page;
          const authorName = bioPage ? `<a href='${ensureHttps(bioPage)}'>${author?.name}</a>` : author?.name;

          return index > 0 ? `, ${authorName}` : authorName;
        }).join('') || '';

        const textElement = {
          _id: inqRandom(),
          type: 'text',
          content: `<em>— ${authorText}</em>`,
        };

        return [...accumulator, textElement];
      }

      return [...accumulator, currentObject];
    }, [])
    .map((item) => {
      const { _id, type, content, items, url, referent, list_type, additional_properties, level, displayPhotos } = item;

      if (referent && !referent?.id) {
        return false;
      }
      if (type === 'reference' && _isEmpty(displayPhotos) === false) {
        let isUpdate = false;
        const photos = displayPhotos.map((photo, index) => {
          if (_isEmpty(photo.referentCaption) === false && photo.referentCaption !== photo.caption) {
            isUpdate = true;
          }

          return {
            "_id": photo._id,
            "caption": _get(photo, 'referentCaption', '') || _get(photo, 'caption', ''),
            "focal_point": { "x": 0, "y": 0 },
            "subtitle": _get(photo, 'subtitle', ''),
            "versionCounter": _get(photo, 'additional_properties.version', 0)
          }
        });

        if (isUpdate) {
          updateImagesInGallery(referent?.id, photos)
        }
      }

      if (typeof content === 'object' && !content?.content) {
        return false;
      }

      return {
        _id: _id,
        type: type,
        ...content && { content },
        ...items?.length > 0 && { items },
        ...url && { url },
        ...referent && { referent: _omit(referent, ['url']) },
        ...list_type && { list_type },
        ...additional_properties && { additional_properties },
        ...level && { level },
      };
    }).filter(item => item);

  if (miniNavCard) {
    const relatedLinks = miniNavCard?.elements?.map((element) => {
      return {
        _id: element?._id,
        type: 'text',
        content: element?.type === 'header' ? `${LIVEBLOG_NAME_TEXT}: ${element.content}` : `${RELATED_LINK_TEXT}: ${element.text} URL: ${element.url}`
      }
    });

    contentElements = [...contentElements, ...relatedLinks];
  }

  if (interactiveHtmlCard) {
    const interactiveHtmlElements = interactiveHtmlCard?.elements?.map((element) => {
      return {
        _id: element?._id,
        type: element?.type,
        content: element?.content
      }
    });

    const divider = {
      _id: inqRandom(),
      type: 'divider',
    };

    contentElements = [...contentElements, divider, ...interactiveHtmlElements];
  }

  const storyLength = getStoryLength(contentElements);

  const arcArticle = await getArcArticleById(id, revisionId);
  const sourceArticle = {
    ..._omit(arcArticle, IGNORE_ARC_FIELDS),
  }

  const mergedArticle = _merge(article?.promo_items?.basic?._id ? article : { ..._omit(article, 'promo_items') }, sourceArticle);

  const savedArticle = {
    ans: {
      ..._omit(mergedArticle, fieldsToExcludeInDraftUpdate),
      ..._isEmpty(article?.promo_items) === false && article?.promo_items?.basic?._id && {
        promo_items: {
          ...article?.promo_items,
          basic: {
            _id: article?.promo_items?.basic?._id,
            referent: {
              id: article?.promo_items?.basic?._id,
              provider: '',
              referent_properties: article?.promo_items?.basic?.referent_properties,
              type: 'image'
            },
            type: 'reference'
          },
          ...(article?.promo_items?.promo?._id && {
            promo: {
              _id: article?.promo_items?.promo?._id,
              referent: {
                id: article?.promo_items?.promo?._id,
                provider: '',
                referent_properties: article?.promo_items?.promo?.referent_properties,
                type: 'image'
              },
              type: 'reference'
            }
          })
        }
      },
      content_elements: contentElements,
      planning: {
        ...article?.planning,
        ...storyLength && { story_length: storyLength },
      },
      ...(mergedArticle?.credits && {
        credits: {
          ...mergedArticle?.credits,
          by: getCreditsAuthors(mergedArticle?.credits),
        }
      })
    },
  };

  return await putData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/draft`, savedArticle, true);
};

const saveSwipeableArcArticleById = async (id, article) => {
  const arcArticle = await getArcArticleById(id);
  let articleForSave = { ans: { ...article } };
  articleForSave.ans.revision = arcArticle.revision;
  let ans = articleForSave.ans;

  ans = {
    ...ans,
    ...{
      taxonomy: {
        ...ans.taxonomy,
        tags: article?.taxonomy?.tags || []
      }
    },
    ...(ans?.credits && {
      credits: {
        ...ans?.credits,
        by: getCreditsAuthors(ans?.credits),
      }
    }),
    ..._isEmpty(article?.promo_items) === false && {
      promo_items: {
        ...article?.promo_items,
        basic: {
          _id: article?.promo_items?.basic?._id,
          referent: {
            id: article?.promo_items?.basic?._id,
            provider: '',
            referent_properties: {
              caption: article?.promo_items?.basic?.caption || '',
              vanity_credits: {
                by: article?.promo_items?.basic?.credits?.by || []
              }
            },
            type: 'image'
          },
          type: 'reference'
        },
        ...(article?.promo_items?.promo?._id && {
          promo: {
            _id: article?.promo_items?.promo?._id,
            referent: {
              id: article?.promo_items?.promo?._id,
              provider: '',
              referent_properties: {
                caption: article?.promo_items?.basic?.caption || '',
                vanity_credits: {
                  by: article?.promo_items?.basic?.credits?.by || []
                }
              },
              type: 'image'
            },
            type: 'reference'
          }
        })
      }
    }
  };

  ans = _omit(ans, fieldsToExcludeInDraftUpdate);
  delete ans?.revision.editions;

  return await putData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/draft`, { ans }, true);
};

const getCirculationsByArticleId = async (id) => {
  return await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/circulation`);
};

const publishAllAssets = (article) => {
  const publishTasks = [];

  if (article?.promo_items?.basic?.referent?.id) {
    publishTasks.push(publishPhoto(article?.promo_items?.basic?.referent?.id));
  }

  if (article?.promo_items?.promo?.referent?.id) {
    publishTasks.push(publishPhoto(article?.promo_items?.promo?.referent?.id));
  }

  article?.content_elements?.forEach((element) => {
    const elementType = element?.referent?.type;

    if (elementType === 'image') publishTasks.push(publishPhoto(element?.referent?.id));
    if (elementType === 'gallery') publishTasks.push(publishGallery(element?.referent?.id));
    if (elementType === 'video') publishTasks.push(publishVideo(element?.referent?.referent_properties?.videoId));
  });

  return Promise.allSettled(publishTasks);
};

const publishArcArticleById = async (id, article, isDirectUpdate = false, revisionId) => {
  await saveDraftArcArticleById(id, article, isDirectUpdate, revisionId);

  const data = await postData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/published`, {}, true);
  const { ans, document_id } = data;

  return { ...ans, _id: document_id };
};

const publishSwipeableImageAssets = async (article) => {
  const publishTasks = [];

  article?.cards?.forEach((card) => {
    const image = card?.data?.image;
    const isPublished = image?.additional_properties?.published;

    if (image?._id && !isPublished) publishTasks.push(publishPhoto(image?._id));
  })

  if (article?.promo_items?.basic?.referent?.id) publishTasks.push(publishPhoto(article?.promo_items?.basic?.referent?.id));

  return Promise.allSettled(publishTasks);
}

const publishSwipeableArcArticleById = async (id, article) => {
  publishSwipeableImageAssets(article);

  await saveSwipeableArcArticleById(id, article);

  const data = await postData(
    `${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/published`,
    {},
    true
  );
  const { ans, document_id } = data;

  return { ...ans, _id: document_id };
};

const unpublishArcArticleById = async (id) => {
  const data = await deleteData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/published`, {}, true);
  const { ans, document_id } = data;

  return { ...ans, _id: document_id };
};

const sendAPNS = (articleId, body) => {
  try {
    postData(`${baseSlsUrl}gdc-live/raw-message-event/${articleId}`, body, false, null, true);
  } catch (error) {
    console.log('Error when sending APNS', error);
  }
}

const triggerLiveblogSocket = (id) => {
  try {
    fetchApi(`${process.env.REACT_APP_LIVEBLOG_SOCKET_URL}trigger-update/${id}`, {});
  } catch (error) {
    console.log('Error during triggering socket: ', error)
  }
};

export const getNewGeneratedURL = async (article) => {
  const circulations = await handleStoryCirculation(article, { dryrun: true });
  const newCirculation = circulations.find((circulation) => circulation?.website_id === 'philly-media-network');

  return newCirculation?.website_url;
};

const regenerateUrl = async (article) => {
  await handleStoryCirculation(article, { dryrun: false, forceUpdate: true });
  await postData(
    `${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${article?._id}/revision/published?regenerate=true`,
    {},
    true
  );
  return getCirculationsByArticleId(article?._id);
};

const storyService = {
  getAutoCuratedStories,
  getStoriesByIds,
  getCuratedStories,
  searchStoriesByGraphQL,
  getDemoArticles,
  addDemoArticles,
  getArticlesByIds,
};

export default storyService;

export {
  getStoryById,
  searchAllLiveBlog,
  getArcArticleById,
  searchArticleByHeadline,
  searchPhoto,
  searchGallery,
  getFullContentArticleById,
  searchVideo,
  publishArcArticleById,
  unpublishArcArticleById,
  saveDraftArcArticleById,
  getCirculationsByArticleId,
  triggerLiveblogSocket,
  sendAPNS,
  searchAllSwipeable,
  getAuthorBySearch,
  getSiteSections,
  getCommissionTeam,
  saveSwipeableArcArticleById,
  publishSwipeableArcArticleById,
  getGlobalTags,
  searchPhotoLightboxes,
  publishAllAssets,
  getLatestRevisionId,
  regenerateUrl,
  getCacheCommissionTeam,
  getCacheSiteSections,
  getCacheGlobalTags,
};

