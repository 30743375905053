export const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

  return pattern.test(str);
}

export const isImageUrl = (url) => {
  try {
    const parsedUrl = new URL(url);

    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(parsedUrl.pathname);
  } catch {
    return false; // Invalid URL
  }
}

export const openNewUrlTab = (url) => {
  window.open(url, '_blank');
};

export const ensureHttps = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }

  return url;
}
